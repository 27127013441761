import React, { useRef, useState, useLayoutEffect, useEffect } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import { breakpoints, Section } from '~styles/global'
const { mobile } = breakpoints

const Blob = ({ className }) => (
  <svg css={css`
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  `}viewBox="0 0 388 188" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M188.122 34.2311C192.358 36.9005 197.75 36.9005 201.985 34.2311L253.122 2.00205C257.358 -0.667333 262.75 -0.66734 266.985 2.00204L318.122 34.2311C322.358 36.9005 327.75 36.9005 331.985 34.2311L368.069 11.4896C376.725 6.03369 388 12.2549 388 22.4876V164.712C388 174.944 376.725 181.166 368.069 175.71L331.985 152.968C327.75 150.299 322.358 150.299 318.122 152.968L266.985 185.197C262.75 187.867 257.358 187.867 253.122 185.197L201.985 152.968C197.75 150.299 192.358 150.299 188.122 152.968L136.985 185.197C132.75 187.867 127.358 187.867 123.122 185.197L71.9852 152.968C67.7497 150.299 62.3577 150.299 58.1223 152.968L19.9314 177.038C11.2747 182.494 0 176.273 0 166.04V21.1593C0 10.9267 11.2746 4.70543 19.9314 10.1614L58.1223 34.2311C62.3577 36.9005 67.7497 36.9005 71.9851 34.2311L123.122 2.00204C127.358 -0.667342 132.75 -0.66735 136.985 2.00203L188.122 34.2311Z" fill="currentColor"/>
  </svg>
)

const CrinkleButton = ({ children, to, className }) => {
  const buttonRef = React.useRef()
  const [blobNum, setBlobNum] = useState(0)

  useLayoutEffect(() => {
    const bWidth = buttonRef.current?.offsetWidth
    if(window.innerWidth > 1024){
      setBlobNum(Math.ceil(bWidth / 150))
    } else {
      setBlobNum(Math.ceil(bWidth / 90))
    }
  }, [children])

  return (
    <Section className={className} css={css`
      padding: 60px 0 60px;
    `}>
      <div css={css`
        grid-column: span 12;
      `}>
        <Link to={to}>
        <button ref={buttonRef}
          css={css`
            color: var(--white);
            padding: 0.75em 1em;
            position: relative;
            margin: 0 auto;
            display: block;
            svg{
              display: block;
            }
          `}
          className="h1"
        >
          <span css={css`
            position: relative;
            z-index: 2;
          `}>
          {children}
          </span>
          <div css={css`
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            color: var(--green);
            z-index: 1;
            /* display: flex; */
            transition: color 0.3s;
            button:hover & {
              color: var(--purple)
            }
          `}>
            <Blob />
            <div css={
              css`
              position: absolute;
              width: 100px;
              height: 100px;
              right: -40px;
              top: 50%;
              transform: translateY(-50%);
              background: currentColor;
              fill: currentColor;
              border-radius: 999px;
              ${mobile}{
                width: 50px;
                height: 50px;
                right: -25px;
              }
              `
            } />
            <div css={
              css`
              position: absolute;
              width: 100px;
              height: 100px;
              left: -40px;
              top: 50%;
              transform: translateY(-50%);
              background: currentColor;
              fill: currentColor;
              border-radius: 999px;
              ${mobile}{
                width: 50px;
                height: 50px;
                left: -25px;
              }
              `
            } />
          </div>
        </button>
        </Link>
      </div>
    </Section>
  )
}

CrinkleButton.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string
}

export default CrinkleButton
